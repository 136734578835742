
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from "./components/Navbar";
import Home from "./components/pages/Home";
import Footer from "./components/Footer";
import Presentation from "./components/pages/Presentation";
import Contact from "./components/pages/Contact";
import Devis from './components/pages/Devis';
import Produit from './components/pages/Produit';
import BoisDeCharpente from './components/pages/BoisDeCharpente';
import AmenagementInterExter from './components/pages/AmenagementInterExter';
import SurMesure from './components/pages/boisDeCharpente/SurMesure';
import Structure from './components/pages/boisDeCharpente/Structure';
import Ossature from './components/pages/boisDeCharpente/Ossature';
import Coffrage from './components/pages/boisDeCharpente/Coffrage';
import Charpente from './components/pages/boisDeCharpente/Charpente';
import Rabotage from './components/pages/Rabotage';
import Realisation from './components/pages/Realisation';
import Mentions from './components/pages/Mentions';

function App() {
  return (
    <BrowserRouter>
        <Navbar/>
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route path="/presentation" element={<Presentation/>}/>
          <Route path="/produits" element={<Produit/>}/>
          <Route path="/bois-de-charpente" element={<BoisDeCharpente/>}/>
          <Route path="/produits-d-amenagement-interieur-et-exterieur" element={<AmenagementInterExter/>}/>
          <Route path="/bois-sur-mesure" element={<SurMesure/>}/>
          <Route path="/bois-de-structure" element={<Structure/>}/>
          <Route path="/bois-d-ossature" element={<Ossature/>}/>
          <Route path="/planche-de-coffrage-volige" element={<Coffrage/>}/>
          <Route path="/planche-de-charpente" element={<Charpente/>}/>
          <Route path="/rabotage-et-traitement" element={<Rabotage/>}/>
          <Route path="/realisation" element={<Realisation/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/devis" element={<Devis/>}/>
          <Route path="/mentions-legales" element={<Mentions/>}/>
        </Routes>
        <Footer/>
    </BrowserRouter>
  );
}

export default App;
